<template>
  <div
    class="v-w-100 v-d-flex v-ui-radio"
    :class="[asColumn ? 'v-flex-column' : 'v-flex-row']"
  >
    <label v-if="!hideLabel">
      <span v-html="label" />
      <span
        v-if="required"
        class="v-required"
      />
    </label>
    <div
      v-for="(option, index) in options"
      :key="`${uid}-${index.toString()}`"
      class="v-ui-radio__single"
    >
      <div class="v-required-radio-block">
        <input
          :id="`v-ui-radio__input-${uid}-${index.toString()}`"
          class="v-ui-radio__input"
          :aria-label="label.toString()"
          :value="option"
          type="radio"
          v-model="picked"
        />
        <label
          class="v-pointer"
          :for="`v-ui-radio__input-${uid}-${index.toString()}`"
        >
          <span class="v-radio" />
          <slot
            :value="option"
            name="option"
          />
        </label>
      </div>
      <div
        v-if="$slots.additional"
        class="v-additonal-block-radio"
      >
        <slot
          :value="option"
          name="additional"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts" generic="T">
import type { VElement } from '@arora/common'

const { selected } = defineProps<
  VElement & {
    selected: T
    options: T[]
    asColumn?: boolean
    hideLabel?: boolean
  }
>()
const emit = defineEmits(['update:selected'])

const uid = useId()

const picked = computed<T>({
  get() {
    return selected
  },
  set(value) {
    emit('update:selected', value)
  }
})
</script>

<style lang="scss">
@use 'assets/variables';

.v-ui-radio {
  gap: 0.8rem;

  &__input {
    display: none;

    + label {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      padding-left: 0;
      margin-bottom: 0;
      margin-right: 1.2rem;

      .v-radio {
        border: 2px solid variables.$Mono400;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        aspect-ratio: 1/1;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 5px;
      }
    }

    &:checked + label {
      .v-radio {
        border: 2px solid variables.$SecondaryBackgroundColor;

        &:before {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          content: '';
          background: variables.$SecondaryBackgroundColor;
        }
      }
    }
  }

  &__single {
    label {
      padding-left: 0;
      margin-bottom: 0;
    }
  }
}
</style>
